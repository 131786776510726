import { fromLonLat, transform } from "ol/proj";

import Constants from "../config/constants";
import View from "ol/View.js";
import { Zoom } from "ol/control";
import { easeOut } from "ol/easing";

export default {
  moveToLocation(map, location, needConvertCoordinate) {
    //notes: OpenLayers default using Web Mercator projection (EPSG:3857), which is (X,Y) coordinates format
    //when convert from long lat (EPSG:4326), such as Mapbox, original coordinate format like Mapbox is (long, lat) -> (X, Y)
    let locationProj = [location.lat, location.long];

    if (needConvertCoordinate) {
      //schladmingWebMercator
      //function naming a bit awkward here
      locationProj = fromLonLat([location.long, location.lat]);
    }
    map.getView().setCenter(locationProj);
    map.getView().setZoom(process.env.VUE_APP_ZOOM_LEVEL);
  },
  animateTo(map, center, zoom, callback = undefined) {
    const animateToOpts = {
      center: center,
      easing: easeOut,
      duration: 500,
      zoom: zoom
    };
    const animateFromOpts = {
      center: map.getView().getCenter(),
      zoom: map.getView().getZoom(),
      easing: easeOut,
      duration: 500
    };
    map
      .getView()
      .animate(animateFromOpts, animateToOpts, () => callback && callback());
  },
  setZoomEnable(map, enabled) {
    const interactions = map.getInteractions();
    //check and set active for zoom-interactions
    interactions.forEach(interaction => {
      if (interaction.constructor.name.indexOf("Zoom") >= 0) {
        interaction.setActive(enabled);
      }
    });

    const controls = map.getControls();
    let zoomControl = controls
      .getArray()
      .filter(control => control instanceof Zoom);

    //check flag and add/remove zoom control
    if (!enabled && zoomControl.length > 0) {
      return map.removeControl(zoomControl[0]);
    }

    if (enabled && zoomControl.length === 0) {
      return map.addControl(new Zoom());
    }
  },

  setPanEnable(map, enabled) {
    const interactions = map.getInteractions();
    //check and set active for zoom-interactions
    interactions.forEach(interaction => {
      if (interaction.constructor.name.indexOf("Drag") >= 0) {
        interaction.setActive(enabled);
      }
    });
  },

  getCurrentCenterLocation(map) {
    const center = map.getView().getCenter();
    return transform(
      center,
      Constants.DEFAULT_FEATURE_PROJECTION,
      Constants.DEFAULT_DATA_PROJECTION
    );
  }
};
